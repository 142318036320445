<template>
  <div>
    <venue-list
      :groupId="groupId"
      :venues="venues"
      :canDelete="true"
      @removeItem="openConfirmationModal"
      @rowClick="openVenue"
    >
      <ez-confirmation-modal ref="deleteModal" type="red">
        <template #title>Delete this {{ $t('global.venue') | lowercase }}?</template>
        <template #content>
          <p>When deleted, this {{ $t('global.venue') | lowercase }}
            will no longer be live on {{ platformName }}.</p>

          <p>You won’t see this {{ $t('global.venue') | lowercase }}
            in admin panel as well.</p>
        </template>
        <template #footer>
          <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
          <ez-button @click="deleteVenue" type="red">Yes, Delete</ez-button>
        </template>
      </ez-confirmation-modal>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="fetchMoreVenuesForGroup" />
    </venue-list>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzButton from '@/components/ui/Button/EzButton';
import flash from '@/components/ui/FlashMessage';
import VenueList from '@/views/common/venues/List.vue';

export default {
  components: {
    EzLoader,
    VenueList,
    EzConfirmationModal,
    EzButton,
    EzLoadMore,
    EzSpinner,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      venues: [],
      meta: {},
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_VENUES_FOR_GROUP);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ADMIN_VENUES_FOR_GROUP);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'adminDeleteVenue',
      'fetchVenuesForGroupAdmin',
    ]),
    async fetchVenuesForGroup(loadingKey = LOADING_KEY.FETCH_ADMIN_VENUES_FOR_GROUP) {
      const query = {
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        limit: 20,
      };
      const { data } = await this.fetchVenuesForGroupAdmin({ groupId: this.groupId, query, loadingKey });
      this.venues = [...this.venues, ...data.data];
      this.meta = { ...this.meta, ...data.meta };
    },
    async fetchMoreVenuesForGroup() {
      await this.fetchVenuesForGroup(LOADING_KEY.FETCH_MORE_ADMIN_VENUES_FOR_GROUP);
    },
    openVenue({ id }) {
      this.$router.push({
        name: 'admin-venue-info',
        params: { id },
      });
    },
    openConfirmationModal(data) {
      this.itemForDelete = data;
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.itemForDelete = {};
      this.$refs.deleteModal.close();
    },
    async deleteVenue() {
      await this.adminDeleteVenue({ venueId: this.itemForDelete.id });

      this.venues = [];
      this.meta = {};
      await this.fetchVenuesForGroup();
      this.closeDeleteModal();
      flash.success({ title: `${this.$t('global.venue')} successfully deleted!` });
    },
  },
  async mounted() {
    await this.fetchVenuesForGroup();
  },
};
</script>
