<template>
  <single-page :entity="venue">
    <template #breadcrumbs>
      <router-link v-if="venueGroup" :to="{ name: 'admin-venue-groups' }">
        {{ $t('global.venues') }}
      </router-link>
      <span>/</span>
      <router-link
        v-if="venueGroup"
        :to="{
          name: 'admin-venue-groups-info',
          params: {
            groupId: groupId,
          },
        }"
      >
        {{ venueGroup.name }}
      </router-link>
    </template>

    <template #title>
      <v-entity-title :entity="venue" v-if="!isLoading" :name="`${venueGroup?.name} - ${venue.name}`" />
    </template>
    <template #actions>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h" />
        </template>
        <template #dropdown>
          <ez-button @click="onDeleteVenue" type="red-link">
            Delete {{ $t('global.venue') }}
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'admin-venue-info' }">
            {{ $t('global.venue') }} Info
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'admin-venue-distributors' }">
            Connected {{ $t('global.distributors') }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'admin-venue-pending-updates' }">Pending Updates</router-link>
        </li>
      </ul>
    </template>
    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Delete this {{ $t('global.venue') | lowercase }}?</template>
      <template #content>
        <p>
          When deleted, this {{ $t('global.venue') | lowercase }} will no longer be live on {{ platformName }}.
        </p>

        <p>You won’t see this {{ $t('global.venue') | lowercase }} in admin panel as well.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteVenue" type="red">Yes, Delete</ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-loader :show="isLoading">Loading...</ez-loader>
  </single-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import EzButton from '@/components/ui/Button';
import VEntityTitle from '@/components/v3/patterns/VEntityTitle';
import SinglePage from '@/views/admin/SinglePage.vue';

export default {
  components: {
    EzConfirmationModal,
    EzButtonDropdown,
    EzButton,
    VEntityTitle,
    SinglePage,
    EzLoader,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      venue: {},
      venueGroup: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_ADMIN_VENUE,
        LOADING_KEY.FETCH_ADMIN_VENUE_GROUP,
      ]);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/venues', ['fetchAdminVenueNew', 'removeVenue']),
    ...mapActions('entities/groups', ['fetchVenueGroup']),
    async fetchVenue() {
      const { data } = await this.fetchAdminVenueNew({ venueId: this.venueId });
      this.venue = data.data;
    },
    async fetchGroup() {
      const { data } = await this.fetchVenueGroup({ groupId: this.groupId });
      this.venueGroup = data.data;
    },
    onDeleteVenue() {
      this.$refs.deleteModal.open();
    },
    confirmDeleteVenue() {
      const { venueId } = this;

      this.removeVenue(venueId).then(() => {
        this.closeDeleteModal();

        this.$router.push({
          name: 'admin-venue-groups-venues',
          params: {
            groupId: this.groupId,
            flash: {
              type: 'success',
              title: `${this.$t('global.venue')} successfully deleted!`,
            },
          },
        });
      });
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
    },
  },
  async mounted() {
    await Promise.all([this.fetchGroup(), this.fetchVenue()]);
  },
};
</script>
