<template>
  <ez-wizard
    :fullWidth="currentStep === VENUE_STEP_2 || currentStep === VENUE_STEP_3"
    @exit="onWizardExit"
    @back="goToPreviousStep"
  >
    <template #title>Add New {{ $t('global.venue') }} Group</template>
    <template #nav>
      <ul>
        <li
          :class="{
            'ez-step-active': currentStep === VENUE_STEP_1,
            'ez-step-completed': currentStep > VENUE_STEP_1,
          }"
        >
          1. Group Info
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === VENUE_STEP_2,
            'ez-step-completed': currentStep > VENUE_STEP_2,
          }"
        >
          2. {{ $t('global.venues') }}
        </li>
        <li
          :class="{
            'ez-step-active': currentStep === VENUE_STEP_3,
            'ez-step-completed': currentStep > VENUE_STEP_3,
          }"
        >
          3. Users
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button :disabled="nextDisabled" @click="goToNextStep">{{ nextCta }}</ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep > VENUE_STEP_1 && currentStep < VENUE_STEP_4"
        type="link"
        formType="button"
        @click="goToPreviousStep"
      >
        <font-awesome-icon icon="arrow-left" />
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>{{ currentStepPageInfo }}</template>
    <component :is="currentStepComponent" @stepCompleted="onStepCompleted" @stepBack="onStepBack" />
  </ez-wizard>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzWizard from '@/components/layout/Wizard.vue';
import EzButton from '@/components/ui/Button';
import steps, { VENUE_STEP_1, VENUE_STEP_2, VENUE_STEP_3, VENUE_STEP_4 } from './steps';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  data() {
    return {
      currentStep: 0,
      nextDisabled: true,
      VENUE_STEP_1,
      VENUE_STEP_2,
      VENUE_STEP_3,
      VENUE_STEP_4,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === VENUE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === VENUE_STEP_4;
    },
    ...mapState('entities/groups', ['draft']),
  },
  watch: {
    currentStep(currentStep) {
      this.UPDATE_DRAFT({ currentStep });
    },
  },
  methods: {
    ...mapMutations('entities/groups', ['UPDATE_DRAFT', 'CLEAR_DRAFT']),
    onWizardExit() {
      this.CLEAR_DRAFT();
      localStorage.removeItem('venueGroupDraft');
      this.$router.push({ name: 'admin-venue-groups' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= VENUE_STEP_1 && step <= VENUE_STEP_4) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= VENUE_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= VENUE_STEP_4) {
        return;
      }

      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    this.currentStep = this.draft.currentStep || VENUE_STEP_1;
  },
};
</script>
