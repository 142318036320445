<script>
/**
 *
 * @version 1.0.0
 * @since 3.0.0
 */

import { mapActions, mapState } from 'vuex';
import EzTable from '@/components/ui/Table/EzTable';
import EzButton from '@/components/ui/Button';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzTable,
    EzButton,
    EzConfirmationModal,
    EzLoadMore,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pendingUpdates: [],
      meta: {},
      selectedForDelete: null,
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
  },
  methods: {
    ...mapActions('entities/venues', [
      'adminFetchPendingUpdates',
      'adminDeletePendingUpdates',
    ]),
    async refresh() {
      const { data } = await this.adminFetchPendingUpdates({
        venueId: this.venueId,
        query: this.meta.nextId ? `nextId=${this.meta.nextId}&limit=20` : 'limit=20',
      });
      this.pendingUpdates = [
        ...this.pendingUpdates,
        ...data.data,
      ];
      this.meta = data.meta;
    },
    onLoadMoreClick() {
      this.refresh();
    },
    handleRowClick({ id }) {
      this.$router.push({
        name: 'new-pending-update',
        params: {
          venueId: this.venueId,
          groupId: this.groupId,
          pendingId: id,
        },
      });
    },
    resetList() {
      this.pendingUpdates = [];
      this.meta = {};
    },
    onRemovePendingUpdate(pendingUpdate) {
      this.selectedForDelete = pendingUpdate;
      this.openDeleteModal();
    },
    openDeleteModal() {
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
      this.selectedForDelete = null;
    },
    async deletePendingUpdate() {
      try {
        const { id } = this.selectedForDelete;
        await this.adminDeletePendingUpdates({
          venueId: this.venueId,
          pendingId: id,
        });
        this.resetList();
        await this.refresh();
        this.closeDeleteModal();
        flash.success({
          title: 'Pending Update successfully deleted!',
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
  },
  async mounted() {
    await this.refresh();
  },
};
</script>
<template>
  <div class="pending-updates-list">
    <ez-table
      class="mt-24"
      :headers="{
        requestedBy: () => 'Requested By',
        createdAt: () => 'Date Requested',
      }"
      :columns="['requestedBy', 'createdAt']"
      :columnProps="{
        createdAt: { class: 'date-cell' },
      }"
      :data="pendingUpdates"
      :removeButton="true"
      @removeItem="onRemovePendingUpdate"
      @rowClick="handleRowClick">
      <template #cell-requestedBy="{ row: { requestedBy } }">
        {{ requestedBy.name }}
      </template>
      <template #cell-createdAt="{ row: { createdAt } }">
        {{ createdAt|date }}
      </template>
    </ez-table>
    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Remove Pending Update?</template>
      <template #content>
        <p>This pending update will be deleted from the list.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="deletePendingUpdate" type="red">Remove Pending Update</ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-load-more v-if="meta.nextId" @loadMore="onLoadMoreClick"></ez-load-more>
  </div>
</template>
<style lang="scss" scoped>
.pending-updates-list {
  :deep() .table {
    td {
      height: 56px;
    }
    .pending-update-status-cell {
      width: 300px;
    }
  }
}
</style>
