<template>
  <single-page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'admin-venue-groups' }">
        <font-awesome-icon icon="arrow-left"/>
        <span>Back to {{ $t('global.venues') }}</span>
      </router-link>
    </template>
    <template #title>
      <v-entity-title :entity="group" />
    </template>
    <template #actions>
      <ez-button
        v-if="canSendInvite"
        type="primary"
        customClass="invitation-button"
        @click="sendInvitation">
        <font-awesome-icon icon="paper-plane"></font-awesome-icon>
        {{ invitationCta }}
      </ez-button>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h"/>
        </template>
        <template #dropdown>
          <ez-button
            @click="onDeleteGroup"
            type="red-link">Delete Group</ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link
            :to="{ name: 'admin-venue-groups-info' }">Group Info</router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'admin-venue-groups-venues' }">
            {{ $t('global.venues') }}
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'admin-venue-groups-users' }">Users</router-link>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="deleteConfirmation" type="red">
      <template #title>
        Delete this {{ $t('global.venue') | lowercase }} group?
      </template>
      <template #content>
        <p>When deleted, this {{ $t('global.venue') | lowercase }}
          group will no longer be live on {{ platformName }}.</p>

        <p>You won’t see this {{ $t('global.venue') | lowercase }}
          group in admin panel as well.</p>
      </template>
      <template #footer>
        <ez-button @click="closeConfirmationModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDelete" type="red">Yes, Delete</ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-loader :show="isLoading">Loading...</ez-loader>
  </single-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACCOUNT_STATUS_INVITE_SENT, ACCOUNT_STATUS_DRAFT, LOADING_KEY } from '@/util/constants';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import VEntityTitle from '@/components/v3/patterns/VEntityTitle';
import SinglePage from '@/views/admin/SinglePage.vue';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    EzConfirmationModal,
    VEntityTitle,
    SinglePage,
    EzLoader,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      group: {},
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_VENUE_GROUP);
    },
    canSendInvite() {
      return [ACCOUNT_STATUS_INVITE_SENT, ACCOUNT_STATUS_DRAFT].includes(this.group.status);
    },
    invitationCta() {
      return this.group.status === ACCOUNT_STATUS_DRAFT ? 'Send Invite' : 'Resend Invite';
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/groups', [
      'fetchVenueGroup',
      'removeGroup',
      'sendGroupInvitation',
    ]),
    onDeleteGroup() {
      this.$refs.deleteConfirmation.open();
    },
    closeConfirmationModal() {
      this.$refs.deleteConfirmation.close();
    },
    confirmDelete() {
      this.removeGroup(this.groupId)
        .then(() => {
          this.$router.replace({
            name: 'admin-venue-groups',
            flash: {
              title: `${this.$t('global.venue')} group deleted.`,
              type: 'success',
            },
          });
        });
    },
    sendInvitation() {
      this.sendGroupInvitation(this.groupId)
        .catch(({ response }) => {
          flash.error({
            title: 'Invitation sending failed!',
            message: response.data.error.message,
          });
        });
    },
  },
  async mounted() {
    const { data } = await this.fetchVenueGroup({ groupId: this.groupId });
    this.group = data.data;
  },
};
</script>
<style scoped lang="scss">
:deep() .admin-single-page {
  &__title {
    margin-bottom: .5rem;
  }
}
</style>
