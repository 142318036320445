<template>
  <div>
    <ez-user-list
      :users="users"
      :columns="['name', 'email', 'role', 'accountName']"
      :headers="{
        accountName: () => `${$t('global.venue')}`,
      }"
      :columnProps="{
        venue: { class: 'venue-cell' },
        role: { class: 'role-cell' },
      }"
      @addUser="onAddUser"
      @userClick="onUserClick"
      @removeUser="onRemoveUser"
    >
      <ez-venue-user-modal
        ref="userModal"
        :roles="roles"
        :formKey="formKey"
        :formAction="formAction"
        :groupId="groupId"
        :userId="selectedUserId"
        :user="selectedUser"
        @close="closeModal"
        @success="onSubmitSuccess"
      />
    </ez-user-list>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzUserList, { EzVenueUserModal } from '@/views/common/users';

export default {
  components: {
    EzLoader,
    EzUserList,
    EzVenueUserModal,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-group-user',
      selectedUserId: null,
      selectedUser: {
        role: { key: null },
        venue: {},
      },
      users: [],
      roles: [],
    };
  },
  computed: {
    formAction() {
      return `admin/venue-groups/${this.groupId}/users`;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_VENUE_GROUP_USERS);
    },
  },
  methods: {
    ...mapActions('entities/users', ['removeAdminVenueUser', 'adminFetchUsers']),
    ...mapActions('roles', ['getAdminVenueRoles']),
    async fetchUsers() {
      const { data } = await this.adminFetchUsers({ groupId: this.groupId });
      this.users = data.data;
    },
    resetUser() {
      this.selectedUserId = -1;
      this.selectedUser = {
        role: { key: null },
        venue: {},
      };
    },
    openModal() {
      this.$refs.userModal.open();
    },
    closeModal() {
      this.resetUser();
    },
    onAddUser() {
      this.resetUser();
      this.openModal();
    },
    onUserClick({ id }) {
      this.selectedUserId = id;
      this.selectedUser = this.users.find(usr => usr.id === id);
      this.openModal();
    },
    async onRemoveUser({ id }) {
      await this.removeAdminVenueUser({ groupId: this.groupId, id });
      await this.fetchUsers();
    },
    async onSubmitSuccess() {
      await this.fetchUsers();
    },
  },
  async mounted() {
    [this.roles] = await Promise.all([this.getAdminVenueRoles(), this.fetchUsers()]);
  },
};
</script>
