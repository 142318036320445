<template>
  <group-form
    :group="group"
    formAction="/admin/venue-groups"
    @submitSuccess="onSubmitSuccess"
  >
    <template #buttons>
      <ez-button form-type="submit">
        Save Changes
      </ez-button>
    </template>
  </group-form>
</template>

<script>
import { mapActions } from 'vuex';
import EzButton from '@/components/ui/Button';
import GroupForm from '@/views/common/venue-groups/Form.vue';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzButton,
    GroupForm,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      group: {},
    };
  },
  methods: {
    ...mapActions('entities/groups', ['fetchVenueGroup']),
    onSubmitSuccess() {
      flash.success({ title: `${this.$t('global.venue')} group successfully updated!` });
    },
  },
  async mounted() {
    const { data } = await this.fetchVenueGroup({ groupId: this.groupId });
    this.group = data.data;
  },
};
</script>

<style scoped lang="scss">
.ez-form {
  max-width: 26rem;
  .button {
    margin-top: 1.5rem;
  }
}
</style>
