import i18n from '@/i18n';

export const VENUE_STEP_1 = 0;
export const VENUE_STEP_2 = 1;
export const VENUE_STEP_3 = 2;
export const VENUE_STEP_4 = 3;

const steps = {
  [VENUE_STEP_1]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/new-venue/Group.vue'),
    title: `Create ${i18n.t('global.venue')} - Group Info`,
    pageTitle: 'Please add group info',
    pageInfo: 'Adding group logo along with the group name increases your chances of being recognized.',
    nextCta: 'Next Step',
  },
  [VENUE_STEP_2]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/new-venue/Venues.vue'),
    title: `Create ${i18n.t('global.venue')} - ${i18n.t('global.venues')}`,
    pageTitle: `Now add ${i18n.t('global.venues').toLowerCase()} to this group`,
    pageInfo: `Add ${i18n.t('global.venues').toLowerCase()} that are going to be a part of this business.`,
    nextCta: 'Next Step',
  },
  [VENUE_STEP_3]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/new-venue/Users.vue'),
    title: `Create ${i18n.t('global.venue')} - Users`,
    pageTitle: 'And now add users',
    pageInfo: 'Add users that are a part of this group and assign their roles.',
    nextCta: 'Go to Overview',
  },
  [VENUE_STEP_4]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/venues/new-venue/Overview.vue'),
    title: `Create ${i18n.t('global.venue')} - Overview`,
    pageTitle: 'Overview',
    pageInfo: 'Before you complete adding a new group, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Finish',
  },
};

export default steps;
