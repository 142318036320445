<template>
  <div class="admin-venue-distributors">
    <div class="filters-actions">
      <ez-filter-list
        :filters="filters"
        @resetFilter="resetFilters"
        @filterUpdated="updateFilters"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          class="search"
          :placeholder="`Search for a ${$t('global.distributor')}`"
          :data-cy="``"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
    </div>
    <ez-table
      :data="distributors"
      :loading="isLoading"
      :columns="['name', 'status', 'actions']"
      :columnProps="{
        actions: { class: 'actions-cell' },
      }"
      disable-hover
    >
      <template #cell-name="{ row }">
        <v-distributor-entity-info :distributor="row" />
      </template>
      <template #cell-status="{ row }">
        <status-badge :status="row.status || 'draft'" />
      </template>
      <template #cell-actions="{ row }">

        <ez-toggle :isActive="row.isConnected" @toggleState="toggleVenueConnect($event, row)">
          <template #active>
            <span>Connected</span>
          </template>
          <template #inactive>
            <span>Connect</span>
          </template>
        </ez-toggle>
      </template>
    </ez-table>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="fetchMoreDistributors" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzToggle from '@/components/ui/Toggle';
import EzTable from '@/components/ui/Table';
import flash from '@/components/ui/FlashMessage';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import StatusBadge from '@/views/common/status-badge';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import { debounce } from '@/util/utils';

export default {
  components: {
    EzInput,
    EzFilterList,
    EzLoadMore,
    EzSpinner,
    EzToggle,
    EzTable,
    VDistributorEntityInfo,
    StatusBadge,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      distributors: [],
      meta: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR,
        LOADING_KEY.ADMIN_CONNECT_VENUE_WITH_DISTRIBUTOR,
        LOADING_KEY.ADMIN_DISCONNECT_VENUE_FROM_DISTRIBUTOR,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ADMIN_VENUE_CONNECTED_SUPPLIERS);
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'connectVenueWithDistributor',
      'disconnectVenueFromDistributor',
      'adminFetchConnectedSuppliers',
    ]),
    async fetchDistributors(loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_CONNECTED_SUPPLIERS) {
      const query = {
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        limit: 20,
        ...this.filters,
      };
      const { data } = await this.adminFetchConnectedSuppliers({ venueId: this.venueId, query, loadingKey });
      this.distributors = [...this.distributors, ...data.data];
      this.meta = data.meta;
    },
    async fetchMoreDistributors() {
      await this.fetchDistributors(LOADING_KEY.FETCH_MORE_ADMIN_VENUE_CONNECTED_SUPPLIERS);
    },
    async refresh() {
      this.resetData();
      await this.fetchDistributors();
    },
    async toggleVenueConnect(connect, row) {
      const data = { venueId: this.venueId, distributorId: row.id };

      if (connect) await this.disconnectVenueFromDistributor(data);
      else await this.connectVenueWithDistributor(data);
      await this.refresh();
    },
    redirectToCreateDistributorWizard() {
      this.$router.push('/suppliers/new');
    },
    async onDistributorCreated() {
      await this.refresh();
      flash.success({ title: `${this.$t('global.distributor')} successfully added!` });
    },
    resetData() {
      this.distributors = [];
      this.meta = {};
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'term') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      } else {
        this.filters = { ...this.filters, [filterName]: event.id };
      }

      this.resetData();
      await this.fetchDistributors();
    }, 300),
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.resetData();
      await this.fetchDistributors();
    },
  },
  async mounted() {
    await this.fetchDistributors();
  },
};
</script>

<style scoped lang="scss">
$table-border-radius: .6em;
$not-listed-text-color: #252631;
$not-listed-border-color: rgba(117, 128, 141, 0.2);

.admin-venue-distributors {
  @include font-size(14px);
  display: flex;
  flex-direction: column;

  .filters-actions {
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  .distributor-form {
    margin: 1.5rem auto;
  }

  .table {
    tr:first-child {
      td:first-child {
        border-top-left-radius: $table-border-radius;
      }

      td:last-child {
        border-top-right-radius: $table-border-radius;
      }
    }

    .actions-cell {
      width: 10rem;
      text-align: right;
    }

    .ez-toggle {
      margin-left: auto;
      max-width: 7.25rem;
    }
  }
}
</style>
