<template>
  <div class="venue-groups">
    <header>
      <div>
        <h1>{{ $t('global.venues') }}</h1>
        <small>{{ meta.totalCount || 0 }} in total</small>
      </div>
      <div class="filters-actions">
        <ez-filter-list
          :filters="filters"
          @resetFilter="resetFilters"
          @filterUpdated="updateFilters"
        >
          <ez-input
            formKey="filters"
            label="search"
            name="search"
            class="search"
            :placeholder="`Search for an ${$t('global.venue')}`"
            :data-cy="``"
          >
            <template #prefix>
              <font-awesome-icon icon="search" />
            </template>
          </ez-input>
          <ez-dropdown name="accountType" formKey="filter-accType" :data="accTypeData" />
        </ez-filter-list>

        <router-link
          :to="{ name: 'admin-venue-groups-new' }"
          class="button button--primary">
          <span>Add New {{ $t('global.venue') }}</span>
        </router-link>
      </div>
    </header>
    <ez-table
      :data="groups"
      :headers="{
        registeredAt: () => 'Date registered',
      }"
      :columns="['name', 'registeredAt', 'status']"
      :columnProps="{
        status: { class: 'medium-cell' },
        registeredAt: { class: 'date-cell' },
      }"
      @rowClick="openVenueGroup"
      :loading="isLoading"
    >
      <template #cell-name="{ row }">
        <v-venue-entity-info :venue="row" />
      </template>
      <template #cell-registeredAt="{ row: { registeredAt } }">
        <div v-if="registeredAt">{{ registeredAt|date }}</div>
        <div v-else>-</div>
      </template>
      <template #cell-status="{ row: { status } }">
        <status-badge :status="status || 'draft'"></status-badge>
      </template>
    </ez-table>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="fetchMoreGroups" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY, venueAccountType } from '@/util/constants';
import EzTable from '@/components/ui/Table';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import StatusBadge from '@/views/common/status-badge';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import { debounce } from '@/util/utils';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';

export default {
  components: {
    EzDropdown,
    EzFilterList,
    EzInput,
    VVenueEntityInfo,
    EzTable,
    EzSpinner,
    EzLoadMore,
    StatusBadge,
  },
  data() {
    return {
      groups: [],
      meta: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ADMIN_VENUE_GROUPS);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ADMIN_VENUE_GROUPS);
    },
    accTypeData() {
      return [
        {
          id: null,
          name: 'All Accounts',
        },
        ...venueAccountType,
      ];
    },
  },
  methods: {
    ...mapActions('entities/groups', ['fetchVenueGroups']),
    restData() {
      this.groups = [];
      this.meta = {};
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.restData();
      await this.fetchGroups();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      } else {
        this.filters = { ...this.filters, [filterName]: event.id };
      }

      this.restData();
      await this.fetchGroups();
    }, 300),
    async fetchGroups(loadingKey = LOADING_KEY.FETCH_ADMIN_VENUE_GROUPS) {
      const query = {
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        limit: 20,
        ...this.filters,
      };
      const { data } = await this.fetchVenueGroups({ query, loadingKey });
      this.groups = [...this.groups, ...data.data];
      this.meta = data.meta;
    },
    async fetchMoreGroups() {
      await this.fetchGroups(LOADING_KEY.FETCH_MORE_ADMIN_VENUE_GROUPS);
    },
    openVenueGroup({ id }) {
      this.$router.push({
        name: 'admin-venue-groups-info',
        params: {
          groupId: id,
        },
      });
    },
  },
  async mounted() {
    await this.fetchGroups();
  },
};
</script>

<style scoped lang="scss">
.venue-groups {
  @extend %list;
  min-height: $loading-min-height;
  height: auto;
  overflow: unset;

  :deep() header {
    flex-direction: column;
    align-items: flex-start;

    h1 {
      @include font-size(24px);
      font-weight: 500;
      margin: 0;
    }

    small {
      @include font-size(12px);
      font-weight: 500;
      margin-top: 0.25rem;
      color: $color-gray-6C;
    }

    .filters-actions {
      width: 100%;
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
    }
  }

  :deep()  {
    .ez-filter-list {
      flex-grow: 1;
    }
  }

  a.button {
    @extend %base-button;
    @include button-type('primary',
      $button-primary-bg,
      $button-primary-color,
      $button-primary-hover-bg,
      $button-primary-color
    ) {
      &:hover {
        background-color: $button-primary-hover-bg;
      }
      &:active {
        background-color: $button-primary-bg;
      }
      text-decoration: none;
    };
  }
}
</style>
